import '../sass/styles.scss';
window.jQuery = $;
import * as $ from 'jquery';
import AOS from 'aos';
import 'aos/dist/aos.css';
import {fuchsia} from "../../../../../wp-includes/js/codemirror/csslint";

document.addEventListener('DOMContentLoaded', () => {

	// Fader plungin
	AOS.init({
		duration: 250,
		once: true,
		offset: 0
	});

	$('.person button').on('click', function (e) {
		e.preventDefault();
	});

	$('nav button').on('click', function (e) {
		e.preventDefault();
		$('.nav-toggle-label').toggleClass('show');
		$('nav').toggleClass('is-open');
		$('.site-wrapper').toggleClass('is-open');
		$('.logo:not(.show)').addClass('show');
		console.log('click');
	});

	$.fn.isInViewport = function() {
		var elementTop = $(this).offset().top;
		var elementBottom = elementTop + $(this).outerHeight();

		var viewportTop = $(window).scrollTop();
		var viewportBottom = viewportTop + $(window).height();

		return elementBottom > viewportTop && elementTop < viewportBottom;
	};
	$(window).on('resize scroll', function() {
		if($('body').hasClass('home')) {
			if ($('.video-wrapper').isInViewport()) {
				$('.logo').removeClass('show');
			} else {
				$('.logo').addClass('show');
			}
		}
	});
});

/*
window.addEventListener('scroll', function() {
	var element = document.querySelector('#contact__block');
	var position = element.getBoundingClientRect();

	// checking whether fully visible
	if(position.top >= 0 && position.bottom <= window.innerHeight) {
		console.log('Element is fully visible in screen');
	}

	// checking for partial visibility
	if(position.top < window.innerHeight && position.bottom >= 0) {
		console.log('Element is partially visible in screen');
		$('.contact__block').addClass('in-view');
	} else {
		$('.contact__block').removeClass('in-view');
	}
});
*/
